<template>
  <div
    class="modal"
    style="z-index: 1005 !important;"
     v-bind:class="{ 'is-active': showRouteHubModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <nav class="level is-flex is-flex-grow-1">
          <strong class="has-text-warning level-left" v-if="saving">{{saving? "Saving...": ""}}</strong>
          <strong class="is-size-6 level-item">Time/Routing Table (Days it take to go from Hub A to Hub B.)</strong>
          <button class="level-right is-success button is-small mx-2">Upload File</button>
          <button class="delete level-right" aria-label="close" @click="toggleClose"></button>
        </nav>
      </header>
      <section class="modal-card-body">
        <ag-grid-vue
          style="width: 92vw; height: 80vh;"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :defaultColDef="defaultColDef"
          @grid-ready="onGridReady"
          id="myGrid"
          @row-editing-started="onRowEditingStarted"
          @row-editing-stopped="onRowEditingStopped"
          @cell-editing-started="onCellEditingStarted"
          @cell-editing-stopped="onCellEditingStopped"
          :rowDrag="false"
          suppressSizeToFit="true"
          suppressResize="true"
        >
        </ag-grid-vue>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

// eslint-disable-next-line import/no-duplicates
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridVue } from "ag-grid-vue";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { hubsCollection } from '@/store/fireStore'
import HubDistanceEditor from '@/components/table/HubDistanceEditor'
import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
import "../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";

LicenseManager.setLicenseKey(
  process.env.VUE_APP_AGGRID_LICENSE_KEY || ""
)

export default {
  name: "HubRouteTable",
  components: {
    AgGridVue,
    hubDistanceEditor: HubDistanceEditor
  },
  data() {
    return {
      saving: false,
      columnDefs: null,
      rowData: null,
      defaultColDef: {
        editable: false,
        sortable: true,
        flex: 1,
        minWidth: 150,
        filter: true,
        resizable: true,
        sizeColumnsToFit: true,
      },
      gridApi: null,
      columnApi: null,
      frameworkComponents: null,
      modules: AllCommunityModules
    };
  },
  props: {
    showRouteHubModal: {
      type: Boolean
    },
  },
  methods: {
    toggleClose () {
      this.$emit('closeModal')
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onRowEditingStarted(event) {
      console.log('never called - not doing row editing');
    },
    onRowEditingStopped(event) {
      console.log('never called - not doing row editing');
    },
    onCellEditingStarted(event) {
      // console.log('cellEditingStarted');
    },
    onCellEditingStopped(event) {
      (async() => {
        this.saving = true
        const { value } = event
        const { headerId: toId } = event.colDef
        const { id: fromId } = event.data
        // console.log('toId:', toId)
        // console.log('toName:', toName)
        // console.log('fromId:', fromId)
        // console.log('fromName:', fromName)
        // console.log('value:', value)
        // console.log('cellEditingStopped');
        // const fromQuery = hubsCollection.doc(fromId)
        // const toQuery = hubsCollection.doc(toId)
        // const fromDoc = await fromQuery.get()
        // const toDoc = await toQuery.get()
        // console.log('fromDoc:', fromDoc.data())
        if (toId !== fromId) {
          await hubsCollection.doc(fromId).set({
            DistanceBetweenHubs: {
              [toId]: value || 0
            }
          }, { merge: true })
          await hubsCollection.doc(toId).set({
            DistanceBetweenHubs: {
              [fromId]: value || 0
            }
          }, { merge: true })
          this.saving = false
        }
      })();
    },
    async retrieveData() {
      const hubRouteDataQuery = await hubsCollection.get()
      const hubRouteData = hubRouteDataQuery.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      const xHub = hubRouteData.map(DistributionZone => ({
        headerName: DistributionZone.DistributionZoneName,
        field: DistributionZone.id,
        cellEditorFramework: "hubDistanceEditor",
        editable: true,
        headerId: DistributionZone.id,
        lockPosition: true,
        sortable: false,
      }))
      xHub.unshift({ field: 'Hub', lockPosition: true, sortable: false, })
      const yHub = hubRouteData.map(DistributionZone => ({
        ...DistributionZone.DistanceBetweenHubs, Hub: DistributionZone.DistributionZoneName, id: DistributionZone.id
      }))
      this.columnDefs = xHub;
      this.rowData = yHub
    }
  },
  beforeMount() {
    (async() => {
      await this.retrieveData()
    })()
    hubsCollection.onSnapshot(async () => {
      await this.retrieveData()
    })
  },
}
</script>

<style scoped>
  .modal-card {
    width: 95%;
  }
</style>
